

































































































































.desktop-menu {
  width: 300px;
  position: absolute;
  left: 0;
  top: 0;
}
